import { API, api } from "./api";

interface IGetCategoryUrl {
  url: string;
}

export const categoryUrl = api.injectEndpoints({
  endpoints: (builder) => ({
    getCategoryUrl: builder.query<IGetCategoryUrl, { categoryCode: string }>({
      query: ({ categoryCode }) => {
        return API.getNewCategoryUrl.endpoint(categoryCode);
      },
    }),
  }),
});

export const { useGetCategoryUrlQuery, useLazyGetCategoryUrlQuery } =
  categoryUrl;

export const { getCategoryUrl } = categoryUrl.endpoints;
