import { useEffect, useMemo } from "react";
import debounce from "lodash.debounce";

const useFunctionDebounce = (
  debouncedFn: (value: any) => void,
  time?: number
) => {
  const debounceFn = useMemo(
    () =>
      debounce((value) => {
        debouncedFn(value);
      }, time ?? 300),
    []
  );

  useEffect(() => {
    return () => debounceFn.cancel();
  }, []);

  return [debounceFn];
};

export default useFunctionDebounce;
