import {
  ISuggestions,
  SimplySuggestions,
  SuggesterResponse,
  SuggestionsWithCategory,
} from "../dto/suggester.types";
import { API, api } from "./api";

export const suggesterResult = api.injectEndpoints({
  endpoints: (builder) => ({
    getSuggestions: builder.query<ISuggestions, string>({
      query: (phrase) => {
        return {
          url: API.getSuggestions.endpoint(phrase),
        };
      },
      transformResponse: (response: SuggesterResponse) => {
        const simplySuggestions: SimplySuggestions[] = [];
        const suggestionsWithCategory: SuggestionsWithCategory[] = [];
        response.suggestions.map(
          (item: SimplySuggestions | SuggestionsWithCategory) => {
            if ("categoryId" in item) {
              suggestionsWithCategory.push(item);
            } else {
              simplySuggestions.push(item);
            }
          }
        );
        return { simplySuggestions, suggestionsWithCategory };
      },
    }),
  }),
});

export const { useGetSuggestionsQuery } = suggesterResult;

export const { getSuggestions } = suggesterResult.endpoints;
