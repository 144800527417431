import React from "react";
import { IIcon } from "./Icon.types";

const CloseIcon: React.FC<IIcon> = ({
  width = "12",
  height = "12",
  fill = "#FFFFFF",
  viewBox = "0 0 14 15",
  onClick,
  className,
}: IIcon): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      stroke={fill}
      viewBox={viewBox}
      onClick={onClick}
      className={className}
      data-testid="closeIcon"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M1 13.5l12-12m-12 0l12 12"
      ></path>
    </svg>
  );
};

export default CloseIcon;
