import React, {
  FormEvent,
  memo,
  PropsWithChildren,
  useRef,
  useState,
} from "react";
import { useRouter } from "next/router";
import { createPortal } from "react-dom";
import { useTheme } from "styled-components";
import * as suggesterResult from "@ecp-redux/api/suggester";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import ConditionalWrapper from "../../../global/components/ConditionalWrapper/ConditionalWrapper";
import { showHeader } from "../../../helpers/customScripts";
import { isTruthy } from "../../../helpers/helpers";
import { isNotEmptyMessage } from "../../../helpers/isNotEmptyMessage";
import useFunctionDebounce from "../../../helpers/useFunctionDebounce";
import { convertColorIdToHex } from "../../../settingsPatterns/settingsPatterns.methods";
import Dropdown from "../../../shared/components/Dropdown/Dropdown";
import DropdownSelectContainer from "../../../shared/components/Dropdown/DropdownSelectContainer";
import { StyledInput } from "../../../shared/components/Input/StyledInput/StyledInput";
import useIsMobilePortal from "../../../shared/hooks/useIsMobilePortal";
import useOnClickOutside from "../../../shared/hooks/useOnClickOutside";
import CloseIcon from "../../../shared/icons/CloseIcon";
import SearchIcon from "../../../shared/icons/SearchIcon/SearchIcon";
import StyledButton from "../../../shared/styleElements/StyledButton/StyledButton";
import { useMessagesSettingsContext } from "../../../structure/Contexts/MessagesSettingsContext";
import { setPublicationZIndex } from "../../BoxMiniCart/BoxMiniCart.methods";
import {
  StyledInputSearchBarWrapper,
  StyledSearchBarForm,
} from "./../BoxSearchBar.styled";
import {
  IBoxSearchBarMessages,
  IBoxSearchBarSettings,
} from "./../BoxSearchBar.types";
import AutoComplete from "./../elements/AutoComplete";

const CreateSearchPortal: React.FC<PropsWithChildren> = ({ children }) => {
  return createPortal(children, document.body);
};

interface SearchBarProps {
  expandSearchBar: boolean;
  hideSearchBar: () => void;
  publicationRef: React.RefObject<HTMLDivElement>;
}

const SearchBar: React.FC<SearchBarProps> = ({
  expandSearchBar,
  hideSearchBar,
  publicationRef,
}) => {
  const { messages, settings } = useMessagesSettingsContext<
    IBoxSearchBarMessages,
    IBoxSearchBarSettings
  >();

  const theme = useTheme() as IThemeState;

  const router = useRouter();
  const initPhrase =
    typeof router.query["phrase"] === "string" ? router.query["phrase"] : "";
  const inputRef = useRef<HTMLInputElement>(null);
  const autocompleteRef = useRef<HTMLFormElement>(null);
  useOnClickOutside(autocompleteRef, () => setIsOpen(false));

  const [searchValue, setSearchValue] = useState(initPhrase);
  const [debounceFn] = useFunctionDebounce(setSearchValue, 50);
  const [isOpen, setIsOpen] = useState(false);

  const isMobile = useIsMobilePortal();

  const { data: suggestions } = suggesterResult.useGetSuggestionsQuery(
    encodeURIComponent(searchValue),
    {
      skip:
        !searchValue ||
        !isTruthy(settings.search_bar_input_search_autocomplete_on),
    }
  );

  const isActualSearchResultsPage: boolean =
    router.asPath.split("?")[0] === settings.search_bar_search_results_path;
  // TODO: all pages is on standardPage (pathname===standardPage)

  function handleSendForm(e: FormEvent) {
    e.preventDefault();
    router.push(
      `${settings.search_bar_search_results_path}${
        searchValue ? `?phrase=${encodeURIComponent(searchValue)}` : ""
      }`,
      undefined,
      { shallow: isActualSearchResultsPage }
    );
    hideSearchBar();
    inputRef?.current?.blur();
    setIsOpen(false);
  }

  const handleCloseSearchBar = () => {
    hideSearchBar();
    isMobile && showHeader();

    if (inputRef.current) {
      inputRef.current.value = "";
      setSearchValue("");
      setIsOpen(false);
    }

    const updatedQuery = router.query;
    delete updatedQuery["phrase"];

    router.push({ query: updatedQuery }, undefined, { shallow: true });
  };

  return (
    <ConditionalWrapper
      condition={isMobile}
      wrapper={(children: React.ReactNode) => (
        <CreateSearchPortal>{children}</CreateSearchPortal>
      )}
    >
      <StyledSearchBarForm
        style={{ zIndex: 99 }}
        onSubmit={handleSendForm}
        ref={autocompleteRef}
        alignment={settings.search_bar_wrapper_align}
        expandSearchBar={expandSearchBar}
      >
        <Dropdown
          isResponsive={false}
          id="SEARCH_BAR"
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onBackDropClick={() => {
            setPublicationZIndex("var(--zindex-publication)", publicationRef);
          }}
          buttonContent={
            <StyledInputSearchBarWrapper className="search-bar-container__input-wrapper">
              <StyledInput
                className="search-bar-container__input-wrapper__input"
                $settings={settings.search_bar_search_input_settings}
                placeholder={messages.search_bar_search_input_placeholder_text}
                ref={inputRef}
                defaultValue={initPhrase}
                type="text"
                onChange={(e) => {
                  debounceFn(e.target.value);
                  setIsOpen(true);
                }}
                onFocus={() => {
                  setPublicationZIndex(
                    "var(--zindex-dropdown-content)",
                    publicationRef
                  );
                  setIsOpen(true);
                }}
              />
              <div className="search-bar-container__mobile-search-icon">
                <SearchIcon
                  onClick={handleSendForm}
                  fill={convertColorIdToHex(
                    settings.search_bar_mobile_loupe_color,
                    theme.colorPalette
                  )}
                />
              </div>

              <div className="search-bar-container__input-wrapper__input__search-icons__container">
                <div className="search-bar-container__input-wrapper__input__search-icon">
                  {isTruthy(settings.search_bar_input_loupe_active) &&
                    searchValue === "" && (
                      <SearchIcon
                        onClick={() => inputRef.current?.focus()}
                        testId="search"
                        fill={convertColorIdToHex(
                          settings.search_bar_mobile_loupe_color,
                          theme.colorPalette
                        )}
                      />
                    )}
                </div>
                {searchValue !== "" && (
                  <CloseIcon
                    className="search-bar-container__input-wrapper__input__remove-icon"
                    data-testid="closeIcon"
                    onClick={handleCloseSearchBar}
                    fill={convertColorIdToHex(
                      settings.remove_icon_color,
                      theme.colorPalette
                    )}
                    width={20}
                    height={20}
                  />
                )}
              </div>
              <div className="search-bar-container__mobile-close-icon">
                <CloseIcon
                  data-testid="closeIcon"
                  onClick={handleCloseSearchBar}
                  fill={convertColorIdToHex(
                    settings.remove_icon_color,
                    theme.colorPalette
                  )}
                  width={20}
                  height={20}
                />
              </div>
            </StyledInputSearchBarWrapper>
          }
          selectContainerContent={
            suggestions &&
            isTruthy(settings.search_bar_input_search_autocomplete_on) &&
            searchValue.length > 0 && (
              <div className="search-bar-container__suggestions">
                <DropdownSelectContainer
                  isOpen={isOpen}
                  className="search-bar-container__suggestions__dropdown-select-container"
                >
                  <AutoComplete
                    simplySuggestions={suggestions.simplySuggestions}
                    suggestionsWithCategory={
                      suggestions.suggestionsWithCategory
                    }
                    onSelect={() => setIsOpen(false)}
                    isActualSearchResultsPage={isActualSearchResultsPage}
                    searchValue={searchValue}
                  />
                </DropdownSelectContainer>
              </div>
            )
          }
        />
        <StyledButton
          renderAs="button"
          type="search"
          $settings={settings.search_bar_search_button_settings}
          className="search-bar-container__search-button"
          show={isNotEmptyMessage(messages.search_bar_search_button_label_text)}
        >
          {messages.search_bar_search_button_label_text}
        </StyledButton>
      </StyledSearchBarForm>
    </ConditionalWrapper>
  );
};

export default memo(SearchBar);
