export const hideHeader = () => {
  if (typeof window !== "undefined") {
    const header = document.querySelector<HTMLElement>("header");
    if (header) header.style.display = "none";
  }
};

export const showHeader = () => {
  if (typeof window !== "undefined") {
    const header = document.querySelector<HTMLElement>("header");
    if (header) header.style.display = "inline";
  }
};

export const setMarginTop = (number: number) => {
  if (typeof window !== "undefined") {
    const body = document.querySelector<HTMLElement>("body");
    if (body) body.style.marginTop = `${number}px`;
  }
};
