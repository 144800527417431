import router from "next/router";
import {
  SimplySuggestions,
  SuggestionsWithCategory,
} from "@ecp-redux/dto/suggester.types";
import { getCategoryTreeName } from "../../../helpers/pageContext";
import { useMessagesSettingsContext } from "../../../structure/Contexts/MessagesSettingsContext";
import { StyledAutocompleteList } from "../BoxSearchBar.styled";
import {
  IBoxSearchBarMessages,
  IBoxSearchBarSettings,
} from "../BoxSearchBar.types";
import TextHighlighter from "./TextHighlighter";
import * as categoryApi from "@ecp-redux/api/categoryUrl";
import { useCallback } from "react";

interface IAutoCompleteProps {
  simplySuggestions: SimplySuggestions[];
  suggestionsWithCategory: SuggestionsWithCategory[];
  isActualSearchResultsPage: boolean;
  searchValue: string;
  onSelect?: () => void;
}

const AutoComplete: React.FC<IAutoCompleteProps> = ({
  simplySuggestions,
  suggestionsWithCategory,
  isActualSearchResultsPage,
  searchValue,
  onSelect,
}) => {
  const { messages, settings } = useMessagesSettingsContext<
    IBoxSearchBarMessages,
    IBoxSearchBarSettings
  >();

  const [trigger] = categoryApi.useLazyGetCategoryUrlQuery();

  const handleAutocomplete = useCallback(
    async (
      suggestedPhrase: string,
      suggestionWithCat?: SuggestionsWithCategory
    ) => {
      if (suggestionWithCat) {
        const { categoryId } = suggestionWithCat;

        trigger({
          categoryCode: categoryId,
        })
          .unwrap()
          .then((categoryUrl) => {
            router.push({
              pathname: categoryUrl.url,
              query: { phrase: suggestedPhrase },
            });
          });
      } else {
        router.push(
          `${settings.search_bar_search_results_path}?phrase=${suggestedPhrase}`,
          undefined,
          { shallow: isActualSearchResultsPage }
        );
      }
      onSelect && onSelect();
    },
    [router, trigger]
  );

  return (
    <StyledAutocompleteList
      $settings={settings}
      renderAs="ul"
      className="autocomplete-container__list"
      onBlur={() => onSelect && onSelect()}
    >
      {simplySuggestions.map((suggestion) => {
        const { phrase } = suggestion;
        return (
          <li
            key={phrase}
            onClick={(e) => {
              e.stopPropagation();
              handleAutocomplete(phrase);
            }}
            className="autocomplete-container__list__list-item"
          >
            <div className="highlightText autocomplete-container__list__list-item__highlight-text">
              <TextHighlighter phrase={phrase} searchValue={searchValue} />
            </div>

            <ul>
              {suggestionsWithCategory
                .filter(({ phrase: catPhrase }) => catPhrase === phrase)
                .map((suggestionWithCat, index) => {
                  const { categoryName, phrase } = suggestionWithCat;

                  return (
                    <li
                      key={`${phrase}_${index}`}
                      className="inCategory autocomplete-container__list__list-item__in-category"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleAutocomplete(phrase, suggestionWithCat);
                      }}
                    >
                      {messages.search_bar_in_category_label} {categoryName}
                    </li>
                  );
                })}
            </ul>
          </li>
        );
      })}
    </StyledAutocompleteList>
  );
};

export default AutoComplete;
