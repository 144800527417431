import classNames from "classnames";

interface TextHighlighterProps {
  phrase: string;
  searchValue: string;
}

const TextHighlighter = ({
  phrase,
  searchValue,
}: TextHighlighterProps): JSX.Element => {
  const cutMark = "!@#)";
  const strRegExp = new RegExp(
    searchValue.toLowerCase().replace(/[#-.]|[[-^]|[?|{}]/g, "\\$&"),
    "g"
  );
  const fullPhraseInElements = phrase
    .toLowerCase()
    .replace(strRegExp, `${cutMark}${searchValue}${cutMark}`)
    .split(cutMark);

  return (
    <>
      {fullPhraseInElements.map((element, index) => (
        <span
          key={`${element}-${index}`}
          className={classNames(
            element.toLowerCase() === searchValue.toLowerCase() && "highlight"
          )}
        >
          {element}
        </span>
      ))}
    </>
  );
};

export default TextHighlighter;
