import styled from "styled-components";
import { IBoxBaseSettings } from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { MOBILE_BREAKPOINT } from "../../global/global";
import composeCss, {
  alignmentOptionsToValue,
} from "../../settingsPatterns/composeCss";
import { convertColorIdToHex } from "../../settingsPatterns/settingsPatterns.methods";
import useIsMobilePortal from "../../shared/hooks/useIsMobilePortal";
import { IBoxSearchBarSettings } from "./BoxSearchBar.types";

export const StyledSearchBarForm = styled.form<{
  alignment: AlignmentHorizontalOption;
  expandSearchBar: boolean;
}>(({ alignment, expandSearchBar }) => {
  const isMobile = useIsMobilePortal();

  return `
      display: flex;
      align-items: center;
      justify-content: ${alignmentOptionsToValue(alignment)};
      gap: 1px;
      height: 100%;

      .search-bar-container__suggestions {
        box-shadow: -4px 1500px 0px 1500px rgba(66, 68, 90, 0.3);
      }

      .search-bar-container {
        &__input-wrapper__input {

          &__search-icons__container {
            position: absolute;
            right: 0.5rem;
            bottom: 50%;
            transform: translateY(50%);
            padding: 0.25rem;
          }
        }

        &__mobile-search-icon {
          display: none;
        }

        &__mobile-close-icon {
          display: none;
        }

        &__suggestions {
        }

        &__search-button {
            margin-left: -1px;
        }
      }

      @media (max-width: ${MOBILE_BREAKPOINT}px) {
        width: 100%;
        height: min-content;
        z-index: var(--zindex-dropdown-content);

        .search-bar-container {
          &__search-button {
            display: none;
          }

          &__mobile-search-icon {
            display: block;
            position: absolute;
            left: 0.5rem;
            bottom: 25%;
            padding: 0.25rem;
          }

          &__mobile-close-icon {
            display: block;
            position: absolute;
            right: 0.5rem;
            bottom: 25%;
            padding: 0.25rem;
          }

          &__input-wrapper__input {
            padding-left: 45px;
  
            &__search-icon {
              display: none;
            }
            
            &__remove-icon  {
              display: none;
            }
          }
        }
        ${
          expandSearchBar &&
          isMobile &&
          `
          position: fixed;
          top: 0;
          left: 0;
          `
        }
    `;
});

export const StyledInputSearchBarWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledAutocompleteList = styled.div<
  IBoxBaseSettings<IBoxSearchBarSettings>
>(({ $settings, theme }) => {
  return `
    max-height: 400px;
    overflow: auto;

    background: ${convertColorIdToHex(
      $settings.searchboks_input_search_autocomplete_background_color,
      theme.colorPalette
    )};
  list-style: none;
  }

  li {
    cursor: pointer;
    color: ${convertColorIdToHex(
      $settings.searchboks_input_search_autocomplete_typography_normal_color,
      theme.colorPalette
    )};
    ${composeCss.font(
      $settings.searchboks_input_search_autocomplete_typography,
      theme.typography
    )};
    width: 100%;
    padding: 15px;
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
    
        :not(:has(li:hover)):hover {
            color: ${convertColorIdToHex(
              $settings.searchboks_input_search_autocomplete_typography_hover_color,
              theme.colorPalette
            )};
            background-color: ${convertColorIdToHex(
              $settings.searchboks_input_search_autocomplete_background_hover_color,
              theme.colorPalette
            )};;
        }



        .highlight {
          color: ${convertColorIdToHex(
            $settings.searchboks_input_search_autocomplete_typography_bold_color,
            theme.colorPalette
          )};
        }
    }

    li .highlightText {
      text-overflow: ellipsis;
      overflow: hidden; 
      white-space: nowrap;
    }

    .inCategory {
        padding-left: 40px;
        ${composeCss.font(
          $settings.searchboks_input_search_autocomplete_category_typography,
          theme.typography
        )};
          color: ${convertColorIdToHex(
            $settings.searchboks_input_search_autocomplete_category_typography_color,
            theme.colorPalette
          )};
    }
`;
});
